import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import Pulse from "@ubisend/pulse-boost";

Pulse.render({
  partner: {
    logo:
      "https://s3.eu-west-2.amazonaws.com/ubisend.website/assets/static/images/logo.svg",
    url: "https://www.ubisend.com/"
  }
});
